export default {
  props: {
    dataFile: {
      type: Object,
    },
    sPurchaseOrderId:{
      type: String
    }
  },
    data() {
      return {
        texts: "",
        screenWidth: 0,
        bLoading: false,
        bChange: false,
      };
    },
    beforeMount() {
      this.texts = FILE.ordersTexts[this.selectLanguage];
    },
    created() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
      this.closeButton();
    },
    methods: {
      downloadFile: function (sFileUrl) {
        window.open(sFileUrl, "blank_");
      },
      clickInputFileVendor: function (id, loadingFile) {
        var inputUploadFile = "inputUploadFileVendor" + id;
        document.getElementById(inputUploadFile).click();
      },
      uploadFilesVendor: function (e, item) {
        // item.loadingFile = true;
        let idFile = e.target.getAttribute("attr-file");
        const file = e.target.files[0];
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };
        const payload = {};
  
        var form = new FormData();
        form.append("file", file);
  
        //AQUI SE SUBE EL ARCHIVO
        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.sPurchaseOrderId}/records/${item.sPurchaseOrderStatusRecordId}/files/${idFile}`,
          form,
          config
        )
          .then((response) => {
            this.mixSuccess(response.data.message);
            this.$store.commit("refresher", true);
            this.bChange = false;
          })
          .catch((error) => {
            item.loadingFile = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      },
      changefile: function(){
        // if(sFileUrl == null){
          this.bChange = true;
        // }else{
        //   this.bChange = false;
        // }
      },
      removeItem: function (item) {
        this.$store.commit("setDelete", {
          active: true,
          title: this.texts.detailOrder.requiredFilesSection.delete.title,
          descipcion: this.texts.detailOrder.requiredFilesSection.delete
            .description,
          note: this.texts.detailOrder.requiredFilesSection.delete.note,
          chkLabel: this.texts.detailOrder.requiredFilesSection.delete.chkLabel,
          api: `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.sPurchaseOrderId}/records/${item.sPurchaseOrderStatusRecordId}/files/${item.sPurchaseOrderFileTypeId}`,
          return: false,
          dobleConfirm: false,
        });
      },
    //   sendUpdateStatus: function() {
    //     this.bLoading = true;
    //     this.$emit("blockBtn", true);
  
    //     const payload = {
    //       sDescription: this.statusDescription,
    //       sPurchaseOrderStatusId: this.statusSelected,
    //     };
  
    //     const config = {
    //       headers: {
    //         Authorization: `Bearer ${this.$store.state.sToken}`,
    //       },
    //     };
  
    //     DB.post(
    //       `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.dialogUpdateStatusArr.oPurchaseOrder.sPurchaseOrderId}`,
    //       payload,
    //       config
    //     )
    //       .then((response) => {
    //         if (this.dataImgs.length > 0) {
    //           this.uploadImgs(
    //             response.data.message,
    //             response.data.results.sPurchaseOrderId,
    //             response.data.results.sPurchaseOrderStatusRecordId
    //           );
    //         } else {
    //           this.bLoading = false;
  
    //           this.closeButton();
    //           this.mixSuccess(response.data.message);
    //           // this.$router.push("/admin/orders");
    //           this.$store.commit("refresher", true);
    //         }
    //       })
    //       .catch((error) => {
    //         this.bLoading = false;
    //         this.$emit("blockBtn", false);
  
    //         this.mixError(error.response.data.message, error.response.status);
    //       });
    //   },
      // removeItem: function(item, index) {
      //   this.dataImgs.splice(index, 1);
      // },
    //   getStatus: function() {
    //     DB.get(
    //       `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.dialogUpdateStatusArr.oPurchaseOrder.oStatusRecord.sOrderStatusId}/adjacency`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.state.sToken}`,
    //         },
    //       }
    //     )
    //       .then((response) => {
    //         this.aStatus = response.data.results.aAdjacency;
    //       })
    //       .catch((error) => {
    //         this.mixError(error.response.data.message, error.response.status);
    //       });
    //   },
      handleResize: function() {
        if (window.innerWidth >= 960) {
          this.screenWidth = 40;
        } else {
          this.screenWidth = 100;
        }
      },
      closeButton: function() {
        this.bChange=false;
        this.$store.commit("setDialogEditFiles", {
          active: false,
          arr: [],
        });
      },
      emitClose: function() {
        this.bChange=false;
        this.$store.commit("setDialogEditFiles", {
          active: false,
          arr: [],
        });
      },
    },
    computed: {
      refresh: function () {
        return this.$store.state.refresh;
      },
      dialogEditFilesActive: function() {
        return this.$store.state.dialogEditFilesActive;

      },
      dialogEditFilesArr: function() {
        return this.$store.state.dialogEditFilesArr;
      },
      selectLanguage: function() {
        return this.$store.state.language;
      },
      validateForm: function() {
        // return (
        //   this.statusSelected !== "" &&
        //   this.statusSelected !== null &&
        //   this.statusDescription !== ""
        // );
      },
    },
    watch: {
      dialogEditFilesActive: function() {

        if (this.dialogEditFilesActive) {
          this.texts = FILE.ordersTexts[this.selectLanguage];
          // this.getStatus();
        }
      },
    },
  };
  